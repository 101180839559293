import { Unit } from '@ambuliz/sabri-core';
import { Avatar, Skeleton, Stack, Typography } from '@mui/material';
import { Accommodated } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { PageSection } from 'core/layout';
import { RefObject } from 'react';
import { UnitManagementFilter } from '.';
import { Grid, Timeline } from '../views';
import { Resource } from './useResources';

type AccommodatingUnitProps = {
  unit: Unit;
  resources: Resource[];
  filter: UnitManagementFilter;
  container?: RefObject<HTMLElement>;
  loading: boolean;
  from: Date;
  to: Date;
  isReadOnly?: boolean;
  focusDate?: Date;
};

const AccommodatingUnit = ({
  unit,
  resources,
  filter,
  container,
  loading,
  from,
  to,
  isReadOnly = false,
  focusDate,
}: AccommodatingUnitProps) => {
  return (
    <>
      <PageSection withBackground noGutter noMargin noPaddingY>
        {loading ? (
          <Stack spacing={2} direction="row" alignItems="center">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={250} height={20} />
          </Stack>
        ) : (
          <Stack spacing={2} direction="row" alignItems="center">
            <Avatar sx={{ width: 20, height: 20, bgcolor: palette.warning.main }}>
              <Accommodated sx={{ fontSize: 12 }} />
            </Avatar>
            <Typography variant="h4">{i18n.accommodatedIn + ' ' + unit.name}</Typography>
          </Stack>
        )}
      </PageSection>
      <PageSection withBackground noGutter noMargin noPadding fullHeight scrollable={filter.view === 'timeline'}>
        {filter.view === 'grid' ? (
          <PageSection withBackground noGutter paddingTop={2}>
            <Grid resources={resources} loading={loading} isReadOnly={isReadOnly} />
          </PageSection>
        ) : (
          <Timeline
            container={container}
            resources={resources}
            period={filter.period}
            loading={loading}
            from={from}
            to={to}
            isReadOnly={isReadOnly}
            focusDate={focusDate}
          />
        )}
      </PageSection>
    </>
  );
};

export default AccommodatingUnit;
