import { Unit } from '@ambuliz/sabri-core';
import { Avatar, Skeleton, Stack, Typography } from '@mui/material';
import { Accommodated } from 'common/components/Icons';
import { i18n } from 'common/locale';
import { palette } from 'common/theme';
import { PageSection } from 'core/layout';
import { RefObject } from 'react';
import { UnitManagementFilter } from '.';
import { List } from '../views';
import AccommodatingUnit from './AccommodatingUnit';
import { Resource } from './useResources';

type AccommodatedResourcesProps = {
  resourcesByUnit: { unit: Unit; resources: Resource[] }[];
  filter: UnitManagementFilter;
  container?: RefObject<HTMLElement>;
  loading: boolean;
  from: Date;
  to: Date;
  isReadOnly?: boolean;
  focusDate?: Date;
};

const AccommodatedResources = ({
  resourcesByUnit,
  filter,
  container,
  loading,
  from,
  to,
  isReadOnly = false,
  focusDate,
}: AccommodatedResourcesProps) => {
  if (filter.view === 'list') {
    const resources = [];

    for (const item of Object.values(resourcesByUnit)) {
      resources.push(...item.resources);
    }

    return resources.length > 0 ? (
      <>
        <PageSection withBackground noGutter noMargin noPaddingY>
          {loading ? (
            <Stack spacing={2} direction="row" alignItems="center">
              <Skeleton variant="circular" width={20} height={20} />
              <Skeleton variant="rectangular" sx={{ borderRadius: 0.5 }} width={250} height={20} />
            </Stack>
          ) : (
            <Stack spacing={2} direction="row">
              <Avatar sx={{ width: 20, height: 20, bgcolor: palette.warning.main }}>
                <Accommodated sx={{ fontSize: 12 }} />
              </Avatar>
              <Typography variant="h4">{i18n.accommodatedPatients}</Typography>
            </Stack>
          )}
        </PageSection>
        <PageSection withBackground noGutter noMargin noPadding>
          <List displayUnitColumn filter={filter} resources={resources} loading={loading} isReadOnly={isReadOnly} />
        </PageSection>
      </>
    ) : null;
  }

  return (
    <>
      {resourcesByUnit.map(({ unit, resources }) =>
        resources.length > 0 ? (
          <AccommodatingUnit
            key={unit.id}
            unit={unit}
            resources={resources}
            filter={filter}
            container={container}
            loading={loading}
            from={from}
            to={to}
            isReadOnly={isReadOnly}
            focusDate={focusDate}
          />
        ) : null
      )}
    </>
  );
};

export default AccommodatedResources;
