import { AccommodationSpecificity, PatientGender, PatientOutcomeType } from '@ambuliz/sabri-core';
import { Period } from 'common/components/Timeline';
import { isToday, parseISO } from 'date-fns';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BedStatus, UnitManagementFilter, View } from './UnitManagementFilters';

const useUnitManagementSearchParams = (disableSwitchView?: boolean) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [filter, setFilter] = useState<UnitManagementFilter>({
    specificities: (searchParams.get('specificities')?.split(',') as AccommodationSpecificity[]) || [],
    statuses: (searchParams.get('statuses')?.split(',') as BedStatus[]) || [],
    view: disableSwitchView ? 'list' : (searchParams.get('view') as View) || 'grid',
    period: (searchParams.get('period') as Period) || 'week',
    genders: (searchParams.get('genders')?.split(',') as PatientGender[]) || [],
    search: searchParams.get('search') || '',
    practitioners: [],
    patientOutcomes: (searchParams.get('patientOutcomes')?.split(',') as PatientOutcomeType[]) || [],
    date: parseISO(searchParams.get('date') || new Date().toISOString()),
  });

  const handleFilterChange = ({
    specificities,
    statuses,
    view,
    period,
    genders,
    search,
    practitioners,
    patientOutcomes,
    date,
  }: UnitManagementFilter) => {
    if (specificities.length === 0) {
      searchParams.delete('specificities');
    } else {
      searchParams.set('specificities', specificities.join(','));
    }

    if (statuses.length === 0) {
      searchParams.delete('statuses');
    } else {
      searchParams.set('statuses', statuses.join(','));
    }

    if (genders.length === 0) {
      searchParams.delete('genders');
    } else {
      searchParams.set('genders', genders.join(','));
    }

    if (patientOutcomes.length === 0) {
      searchParams.delete('patientOutcomes');
    } else {
      searchParams.set('patientOutcomes', patientOutcomes.join(','));
    }

    if (view === 'grid') {
      searchParams.delete('view');
    } else {
      searchParams.set('view', view);
    }

    if (view !== 'timeline' || period === 'week') {
      searchParams.delete('period');
    } else {
      searchParams.set('period', period);
    }

    if (search) {
      searchParams.set('search', search);
    } else {
      searchParams.delete('search');
    }

    if (date && !isToday(date)) {
      searchParams.set('date', date.toISOString());
    } else {
      searchParams.delete('date');
    }

    setSearchParams(searchParams);
    setFilter({
      specificities,
      statuses,
      view: disableSwitchView ? 'list' : view,
      period,
      genders,
      search,
      practitioners,
      patientOutcomes,
      date,
    });
  };

  return [filter, handleFilterChange] as const;
};

export default useUnitManagementSearchParams;
